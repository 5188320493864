






import { Component, Emit, Vue } from 'vue-property-decorator';
import { WPPost, CourseResponseCourses, CourseResponse } from '../../../types/typescript-axios/api';
import VueRouter, {Route} from 'vue-router';
import axios, {AxiosResponse, AxiosError} from 'axios';
import Error from '@/components/contents/Error.vue';
import store from '@/store';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
]);

async function getContentsData(url: string, params: any = {}, headers: any = {}) {
  const res = await new Promise((resolve, reject) => {
    axios.get<CourseResponse>(url, {
      params,
      headers,
    })
    .then( (response) => {
      resolve(response);
    })
    .catch( (e: AxiosError) => {
      reject(e);
    });
  });
  return res;
}

async function getDatas(to: Route) {
  let status: number = 200;
  const $to: any = to;
  const paramsName: string = $to.params.courceId;
  let contentsData: CourseResponseCourses;
  if ($to.query.preview) {
    const params = {
      status: 'auto-draft',
    };
    const headers = { 'X-WP-Nonce': window.WP_API_Settings.nonce };

    try {
      const res: any = await getContentsData(`/wp-json/moc/course/${paramsName}/revisions`, params, headers);
      if (res.data.courses.length > 0) {
        contentsData = res.data.courses[0];
      } else {
        return {
          status: 404,
        };
      }
    } catch (e) {
      return {
        status: e.response.status,
      };
    }
  } else {
    try {
      const res = await axios.get<CourseResponse>(`/wp-json/moc/course/${paramsName}/detail`);
      contentsData = res.data.courses[0];
      status = res.status;
      // if (Array.isArray(contentsData.data1)) {
      //   return {
      //     status: 500,
      //   };
      // }
    } catch ( error: any ) {
      return {
        status: error.response.status,
      };
    }
  }
  return {
    status,
    contentsData,
    paramsName,
    $to,
  };
}

@Component({
  components: {
    Error,
  },
})
export default class Detail extends Vue {
  private title: any = '';
  private courseDetail: CourseResponseCourses|null = null;
  private etcData: WPPost[] = [];
  private status = 200;
  private paramsName: number = 0;

  private stepNavi: StepNaviData[] = [
    {
      title: '確認・申込み',
    },
    {
      title: '申込み完了',
    },
  ];

  @Emit()
  private setCourseDetail(value: any): void {
    this.courseDetail = value;
  }
  @Emit()
  private setTitle(value: any): void {
    this.title = value;
  }
  @Emit()
  private setEtcData(value: any): void {
    this.etcData = value;
  }

  private async mounted() {
    const url: string = '/wp-json/moc/course/';
    const data = await axios.get<AxiosResponse>(url, {
      params: {
        status: 'accepting',
        orderby: 'event_date',
        per_page: 9,
      },
    });
    const closedData = await axios.get<AxiosResponse>(url, {
      params: {
        status: 'closed',
        orderby: 'event_date',
        per_page: 9,
      },
    });
    const etcData: any = data.data;
    const closedEtcData: any = closedData.data;
    const newEtcData = etcData.courses.concat(closedEtcData.courses);
    const newData = newEtcData.filter((value: any, index: number) => {
      if (index < 9 && value.data2.slug !== this.paramsName) {
        return true;
      }
    });
    this.etcData = newData;
  }
  private async beforeRouteEnter(to: Route, from: Route, next: any) {
    const data: any = await getDatas(to);
    if (data.status === 200) {
      const titleData: any = data.contentsData;
      store.commit('window/setTitle', titleData.data2.title.rendered);
      next((vm: {
        title: any,
        courseDetail: CourseResponseCourses,
        // etcData: WPPost,
        paramsName: number,
        status: number,
      }) => {
        vm.status = data.status;
        vm.courseDetail = data.contentsData;
        // vm.etcData = data.newData;
        vm.title = titleData.data2.title.rendered;
        vm.paramsName = Number(data.$to.params.courceId);
      });
    } else {
      store.commit('window/setTitle', data.status + ' Error');
      next((vm: {status: number}) => {
        vm.status = data.status;
      });
    }
  }
  private async beforeRouteUpdate(to: Route, from: Route, next: any) {
    const data: any = await getDatas(to);
    const titleData: any = data.contentsData;
    store.commit('window/setTitle', titleData.data2.title.rendered);
    this.status = data.status;
    if (data.status === 200) {
      const $to: any = to;
      this.courseDetail = data.contentsData;
      // this.etcData = data.newData;
      this.title = titleData.data2.title.rendered;
      this.paramsName = Number($to.params.courceId);
    } else {
      store.commit('window/setTitle', data.status + ' Error');
    }
    next();
  }
}
